<template>
    <div class='app-container'>
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="用户名:">
                <el-input v-model="search.name" size="small" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="角色类型:">
                <el-select v-model="search.type" size="small" clearable placeholder="请选择状态" class="form-line-item">
                    <el-option label="院校" :value="2"></el-option>
                    <el-option label="学生" :value="0"></el-option>
                    <el-option label="导师" :value="2"></el-option>
                    <el-option label="管理" :value="3"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <!-- 弹窗 -->
                    <el-button type="success" size="mini" icon="el-icon-plus" @click="addUserShow">添加用户
                    </el-button>
                    <!-- <el-button type="success" size="mini" icon="el-icon-plus" @click="insertAuthorityHandle">添加角色
                    </el-button> -->
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar reviewBookList" round align="left"
                ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :data="strategyList">
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="username" title="用户名" width="300" :show-overflow="'tooltip'"></vxe-column>
                <!-- <vxe-column field="comment" title="用户昵称" width="300" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column field="webRoleNames" title="角色类型" width="200" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column title="操作" min-width="200">
                    <template v-slot="scope" #default="{ row }">
                        <!-- click触发 -> 后端调数据 -> 前端显示 index/vxe-modal -->
                        <el-button size="small" type="text" @click="viewWebRolesAuthority(scope.row)">查看</el-button>
                        <el-button size="small" type="text" id="changeWebRolesAuthority" @click="changeWebRolesAuthority(scope.row)">修改</el-button>
                        <el-popconfirm title="是否确认移除？" @confirm="removeUser(scope.row)" style="margin-left: 10px">
                          <el-button type="text" size="small" slot="reference" style="color: red">移除用户</el-button>
                        </el-popconfirm>
                        <!-- <el-button size="small" type="text">删除</el-button> -->
                    </template>
                </vxe-column>
            </vxe-table>

            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>

        <!-- 添加用户 -->
        <vxe-modal v-model="isAddUserShow" :position="{top: 5}" width="700" show-zoom resize :transfer='true'>
            <template #title>
                添加用户
            </template>
            <div>
                <el-form ref="sysUser" status-icon :model="sysUser" :rules="rules">
                    <el-form-item label="新建用户名" prop="sysUserName">
                        <el-input v-model="sysUser.sysUserName" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="sysUserPassword">
                        <el-input type="password" v-model="sysUser.sysUserPassword" autocomplete="off" show-password
                            placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="sysUserPasswordCheck">
                        <el-input type="password" v-model="sysUser.sysUserPasswordCheck" autocomplete="off" show-password
                            placeholder="请再次输入密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addUser">立即创建</el-button>
                        <el-button @click="resetForm('sysUser')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </vxe-modal>
        
        <!-- 查看 -->
        <vxe-modal v-model="isVisibleShow" :position="{top: 5}" width="700" show-zoom resize :transfer='true'>
            <template #title>
                查看角色类型权限
            </template>
            <el-row :gutter = "10">
                <el-col :span="12">
                    <div>
                        <el-collapse accordion>
                            <template v-for = "item in this.webRolesList">
                                <el-collapse-item>
                                    <template slot="title">{{ item.webRoleNames }}</template>
                                    <div>
                                        <el-tree :data=item.webRoleAuthorityList ref="tree" node-key="id" :props="defaultProps"></el-tree>
                                    </div>
                                </el-collapse-item>
                            </template>
                        </el-collapse>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div>
                        <el-collapse>
                            <el-collapse-item title="所有权限">
                                <el-tree :data="webRoleAllAuthorityList" ref="tree" node-key="id" :props="defaultProps"></el-tree>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </el-col>
            </el-row>
        </vxe-modal>

        <!-- 修改 -->
        <vxe-modal v-model="isChangeShow" :position="{top: 5}" width="700" show-zoom resize :transfer='true'>
            <template #title>
                修改角色类型权限
            </template>
            <el-row :gutter = "10">
                <el-col :span="12">
                    <div>
                        <el-collapse accordion>
                            <template v-for = "item in this.webRolesList">
                                <el-collapse-item>
                                    <template slot="title">{{ item.webRoleNames }}
                                        <!-- click 后端删除相应 前端刷新 -->
                                        <el-popconfirm title="是否确认移除角色？" @confirm="removeWebRoles(item.webRoleNames)">
                                            <el-button slot="reference" @click.stop="">移除角色</el-button>                    
                                        </el-popconfirm>
                                    </template>
                                    <div>
                                        <el-tree :data=item.webRoleAuthorityList ref="tree" node-key="id" :props="defaultProps"></el-tree>
                                    </div>
                                </el-collapse-item>
                            </template>
                        </el-collapse>
                        <!-- click 角色应当已存在 下拉框点击然后添加！！！ distribution中增加 -->
                        <template>
                            <el-select v-model="value" clearable placeholder="请选择">
                                <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                            <el-popconfirm title="是否确认添加角色？" @confirm="addWebRoles">
                                <el-button slot="reference">添加角色</el-button>
                            </el-popconfirm>
                        </template>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div>
                        <el-collapse>
                            <el-collapse-item title="所有权限">
                                <el-tree :data="webRoleAllAuthorityList" ref="tree" node-key="id" :props="defaultProps"></el-tree>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </el-col>
            </el-row>
        </vxe-modal>
    </div>
</template>

<script>
    export default {
        data() {
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.sysUser.sysUserPasswordCheck !== '') {
                        this.$refs.sysUser.validateField('sysUserPasswordCheck');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.sysUser.sysUserPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                search: {
                    name: '',
                    type: ''
                },
                strategyList: [{
                    // name: '策略名称',
                    // type: '角色类型',
                    // comment: '策略说明'
                }],
                webRolesList: [{
                    webRoleNames: 'A',
                    webRoleAuthorityList: [{
                    id: 10001,
                    label: '送审管理2333333333',
                    children: [{
                            id: 20001,
                            label: '送审管理_发送邀审信息'
                        },
                        {
                            id: 20002,
                            label: '送审管理_匹配专家'
                        }, {
                            id: 20003,
                            label: '送审管理_同批复匹'
                        }, {
                            id: 20004,
                            label: '送审管理_导出送审记录'
                        },{
                            id: 20005,
                            label: '送审管理_发送催审信息'
                        },{
                            id: 20006,
                            label: '送审管理_批量修改送审规则'
                        },{
                            id: 20007,
                            label: '送审管理_下载送审规则'
                        },{
                            id: 20008,
                            label: '送审管理_上传送审规则'
                        },{
                            id: 20009,
                            label: '送审管理_送审情况',
                            children:[{
                                id: 30012,
                                label: '送审管理_送审情况_匹配专家'
                            },{
                                id: 30013,
                                label: '送审管理_送审情况_同批复匹'
                            },{
                                id: 30014,
                                label: '送审管理_送审情况_新增专家'
                            },{
                                id: 30015,
                                label: '送审管理_送审情况_恢复'
                            },{
                                id: 30016,
                                label: '送审管理_送审情况_退回'
                            },{
                                id: 30017,
                                label: '送审管理_送审情况_撤回'
                            }]
                        },{
                            id: 20010,
                            label: '送审管理_修改送审规则'
                        },
                        {
                            id: 20011,
                            label: '送审管理_备注'
                        }
                    ]
                }]
                },{
                    webRoleNames: "B",
                    webRoleAuthorityList: [{
                    id: 10001,
                    label: '送审管理2333333333',
                    children: [{
                            id: 20001,
                            label: '送审管理_发送邀审信息'
                        },
                        {
                            id: 20002,
                            label: '送审管理_匹配专家'
                        }, {
                            id: 20003,
                            label: '送审管理_同批复匹'
                        }, {
                            id: 20004,
                            label: '送审管理_导出送审记录'
                        },{
                            id: 20005,
                            label: '送审管理_发送催审信息'
                        },{
                            id: 20006,
                            label: '送审管理_批量修改送审规则'
                        },{
                            id: 20007,
                            label: '送审管理_下载送审规则'
                        },{
                            id: 20008,
                            label: '送审管理_上传送审规则'
                        },{
                            id: 20009,
                            label: '送审管理_送审情况',
                            children:[{
                                id: 30012,
                                label: '送审管理_送审情况_匹配专家'
                            },{
                                id: 30013,
                                label: '送审管理_送审情况_同批复匹'
                            },{
                                id: 30014,
                                label: '送审管理_送审情况_新增专家'
                            },{
                                id: 30015,
                                label: '送审管理_送审情况_恢复'
                            },{
                                id: 30016,
                                label: '送审管理_送审情况_退回'
                            },{
                                id: 30017,
                                label: '送审管理_送审情况_撤回'
                            }]
                        },{
                            id: 20010,
                            label: '送审管理_修改送审规则'
                        },
                        {
                            id: 20011,
                            label: '送审管理_备注'
                        }
                    ]
                }]
                }],
                webRoleAllAuthorityList:[{
                    id: 10001,
                    label: '送审管理2333333333',
                    children: [{
                            id: 20001,
                            label: '送审管理_发送邀审信息'
                        },
                        {
                            id: 20002,
                            label: '送审管理_匹配专家'
                        }, {
                            id: 20003,
                            label: '送审管理_同批复匹'
                        }, {
                            id: 20004,
                            label: '送审管理_导出送审记录'
                        },{
                            id: 20005,
                            label: '送审管理_发送催审信息'
                        },{
                            id: 20006,
                            label: '送审管理_批量修改送审规则'
                        },{
                            id: 20007,
                            label: '送审管理_下载送审规则'
                        },{
                            id: 20008,
                            label: '送审管理_上传送审规则'
                        },{
                            id: 20009,
                            label: '送审管理_送审情况',
                            children:[{
                                id: 30012,
                                label: '送审管理_送审情况_匹配专家'
                            },{
                                id: 30013,
                                label: '送审管理_送审情况_同批复匹'
                            },{
                                id: 30014,
                                label: '送审管理_送审情况_新增专家'
                            },{
                                id: 30015,
                                label: '送审管理_送审情况_恢复'
                            },{
                                id: 30016,
                                label: '送审管理_送审情况_退回'
                            },{
                                id: 30017,
                                label: '送审管理_送审情况_撤回'
                            }]
                        },{
                            id: 20010,
                            label: '送审管理_修改送审规则'
                        },
                        {
                            id: 20011,
                            label: '送审管理_备注'
                        }
                    ]
                }],
                isAddUserShow: false,
                isVisibleShow: false,
                isChangeShow: false,
                options:[{
                    id: 1,
                    label: 'A',
                },{
                    id: 2,
                    label: 'B',
                }],
                value: '',
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                curUsername: '',
                curWebRoleNames: [],
                sysUser: {
                    sysUserName: '',
                    sysUserPassword: '',
                    sysUserPasswordCheck: ''
                },
                rules:{
                    sysUserPassword: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    sysUserPasswordCheck: [
                        { validator: validatePass2, trigger: 'blur' }
                    ],
                },
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [10, 15, 20, 50, 100],
                    total: 100
                }
            }
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
            this.getWebRoles();
        },
        methods: {
            handleKeyPress(event) {
                if (event.keyCode === 13) {
                    this.batchSearch();
                }
            },

            // 获取论文列表
            getWebRoles() {
                let param = {
                    // "username": this.$store.state.user.info.name,
                    "username": this.search.name,
                    "pageNum": this.page.currentPage,
                    "pageSize": this.page.pageSize
                }
              console.log(param);
                this.$api.authority.getWebRoles(param)
                    .then(res => {
                        if (res.data.code == 200) {
                            console.log(res.data.data);
                            this.strategyList = res.data.data;
                            this.page.total = res.data.count;
                            this.loading = false;
                        }
                    }).catch(err => {
                        // this.cancelToken.getP2pPaperSource = null;
                        this.$message.warning("服务器维护");
                    })
            },
            // 重置创建用户
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 创建用户
            addUser(){
                this.$refs['sysUser'].validate((valid) => {
                    if(valid){
                        let params = {
                            "id": null,
                            "username": this.sysUser.sysUserName,
                            "roles": [
                                {
                                    "id": 1
                                }
                            ],
                            "password": this.sysUser.sysUserPassword
                        }
                        console.log(params);
                        this.$api.user.addUserWeb(params)
                            .then(res => {
                                if(res.data.code == 200){
                                    this.$message.success(res.data.msg);
                                    this.refresh();
                                    this.isAddUserShow = false;
                                }else if(res.data.code == 999){
                                    this.$message.warning(res.data.msg);
                                }
                            }).catch(err => {
                                this.$message.warning("服务器维护")
                            })
                    }else{
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
            // 查看
            viewWebRolesAuthority(row){
                let params = {
                    "webRoleNames": row.webRoleNames
                }
                this.getWebRolesAuthority(params);
                this.isVisibleShow = true;
            },
            // 修改
            changeWebRolesAuthority(row){
                console.log(row);
                let params = {
                    "webRoleNames": row.webRoleNames
                }
                this.getWebRolesAuthority(params);
                this.isChangeShow = true;
                this.curUsername = row.username;
                this.curWebRoleNames = row.webRoleNames;
                console.log(this.curWebRoleNames);
                this.getAddWebRolesList();
            },
            // 移除角色
            removeWebRoles(webRoleNames){
                let params1 = {
                    "username": this.curUsername,
                    "webRoleName": webRoleNames
                }
                // console.log(this.curWebRoleNames.split(',').length);
                if(this.curWebRoleNames.split(',').length == 1){
                    this.$message.warning("每位用户至少需要分配一名角色！");
                }else{
                    // 前端移除
                    // this.webRolesList = this.webRolesList.filter(item => item.webRoleNames != params1.webRoleName);
                    // 后端移除
                    this.$api.authority.removeWebRoles(params1)
                    .then(res => {
                        if(res.data.code == 200){
                            this.$message.success("移除角色成功");
                            this.curWebRoleNames = this.curWebRoleNames.split(",");
                            this.curWebRoleNames = this.curWebRoleNames.filter(item => item != webRoleNames);
                            this.curWebRoleNames = this.curWebRoleNames.join(',');
                            let params2 = {
                                "webRoleNames": this.curWebRoleNames
                            }
                            this.getWebRolesAuthority(params2);
                            this.refresh();
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");               
                    })
                }
            },
            // 添加角色
            addWebRoles(){
                console.log(this.value);
                console.log(this.curUsername);
                let isRepeat = this.webRolesList.filter(item => item.webRoleNames == this.value);
                console.log(isRepeat.length);
                if(isRepeat.length >= 1){
                    this.$message.warning('该角色已存在，请勿重复添加');
                }else{
                    let params = {
                        "label": this.curUsername,
                        "id": this.options.filter(item => item.label == this.value)[0].id
                    }
                    this.$api.authority.addWebRoles(params)
                        .then(res => {
                            if(res.data.code == 200){
                                this.$message.success('添加成功');
                                // 后续刷新还没做
                                this.curWebRoleNames = this.curWebRoleNames + ',' + this.value;
                                let params2 = {
                                    "webRoleNames": this.curWebRoleNames
                                }
                                this.getWebRolesAuthority(params2);
                                this.refresh();
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护");
                        })
                };
            },
            // 获取所有权限
            getWebRolesAuthority(params){
                // 各角色权限
                this.$api.authority.getWebRolesAuthority(params)
                .then(res => {
                    console.log(res.data);
                    if(res.data.code == 200){
                    this.webRolesList = res.data.data;
                    }
                }).catch(err => {
                    this.$message.warning("服务器维护");
                })
                // 角色所有权限
                this.$api.authority.getWebRolesAllAuthority(params)
                .then(res => {
                    console.log(res.data);
                    if(res.data.code == 200){
                        this.webRoleAllAuthorityList = res.data.data;
                    }
                }).catch(err => {
                    this.$message.warning("服务器维护");
                })
            },
            // 获取添加角色列表
            getAddWebRolesList(){
                this.$api.authority.getAddWebRolesList()
                    .then(res => {
                        if(res.data.code == 200){
                            this.options = res.data.data;
                            console.log(this.options);
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            removeUser(row){
              let params = {
                "id": row.id
              }
              this.$api.authority.removeUser(params)
                  .then(res => {
                    if (res.data.code == 200){
                      this.$message.success("移除成功");
                      this.refresh();
                    }
                  }).catch(err => {
                    this.$message.warning("服务器维护");
                    this.refresh();
              })
            },
            //表格刷新
            refresh() {
                this.getWebRoles()
            },
            resetHandle(){
                this.search ={
                    name: ''
                    // type: ''
                };
            },
            batchSearch(){
                this.page.pageIndex = 1;
                this.getWebRoles();
            },
            addUserShow(){
                this.isAddUserShow = true;
            },
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getWebRoles();
            },
            //每页多少数据改变
            handleChangePageSize(val) {
              this.page.pageSize = val;
              this.page.currentPage = 1;
              this.getWebRoles();
            },
            firstPage() {
              this.page.currentPage = 1;
              this.getWebRoles();
            },
            endPage() {
              this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                  .pageSize == 0 ? 0 : 1);
              this.getWebRoles();
            },
            headerCellClassName({
                                  column,
                                  columnIndex
                                }) {
              return 'vxe-table-thead'
            },
            headerCellStyle({
                              row,
                              column,
                              rowIndex,
                              columnIndex
                            }) {
              return "background-color:#e0e0e0f1"
            },
            scroll(e) {
              console.log('scroll', e.target.scrollTop)
            },
            insertAuthorityHandle() {
                this.$router.push({
                    name:'insertStrategy'
                })
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>